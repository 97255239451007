/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import "../../pages/registration/registration.css";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ConsentDialog from "../../components/common/ConsentDialog";
import AccordionDetails from "@mui/material/AccordionDetails";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";
import { AddCircle, Email, RemoveCircle } from "@mui/icons-material";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import toast from "react-hot-toast";

//-------------------------------------------------------------------------------------------------

const schema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  nakshatram: yup.string().nullable(),
  gotram: yup.string().nullable(),
  pooja_id: yup.string().required("Please select a service"),
  starting_date: yup.date().required("Please select a date").nullable(),
});

const defaultValues = {
  pooja_id: "",
};

const payload = {
  country_id: "611ce96de2cd656171314e2a",
  state_id: "611ceb61e2cd656171314e69",
  nakshatram_id: "611cec0ee2cd656171314edf",
  language_id: "611d063ee2cd656171314ee8",
};

//0----------------------------------------------------------------------------------------------------
function ExpressBookingServices() {
  const [visibleRows, setVisibleRows] = useState(3);
  const [quantity, setQuantity] = useState(1);

  const [monthlyServiceData, setMonthlyServiceData] = useState();
  const [weeklyServiceData, setWeeklyServiceData] = useState();
  const [dailyServiceData, setDailyServiceData] = useState();
  const [serviceData, setServiceData] = useState();
  const cartArr = useSelector((state) => state.cartArr);
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const isDevoteeLoggedIn = localStorage.getItem("token");
  const dispatch = useDispatch();

  // Function to increment quantity
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // Function to decrement quantity, ensuring it doesn't go below 1
  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleChildrenRemove = (index) => {
    remove(index);
  };

  const [nakshatra, setNakshatra] = useState();

  const navigate = useNavigate();

  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // will directly update input value
  const filterById = (id) => {
    const monthlyMatch = monthlyServiceData.filter((item) => item._id === id);
    const weeklyMatch = weeklyServiceData.filter((item) => item._id === id);
    const dailyMatch = dailyServiceData.filter((item) => item._id === id);

    const filteredData = [...monthlyMatch, ...weeklyMatch, ...dailyMatch];
    return filteredData;
  };

  useEffect(() => {
    reset({
      first_name: devoteeData?.first_name ?? "",
      last_name: devoteeData?.last_name ?? "",
      "persons.0.gotram": devoteeData?.gotram ?? "",
      "persons.0.nakshatram": devoteeData?.nakshatram ?? "",
      ...(devoteeData?.childrens?.length > 0
        ? {
            "persons.1.first_name": devoteeData.childrens[0].first_name ?? "",
            "persons.1.last_name": devoteeData.childrens[0].last_name ?? "",
            "persons.1.nakshatram": devoteeData.childrens[0].nakshatram ?? "",
          }
        : {}),
      ...(devoteeData?.spouse?.length > 0
        ? {
            "persons.2.first_name": devoteeData.spouse[0].first_name ?? "",
            "persons.2.last_name": devoteeData.spouse[0].last_name ?? "",
            "persons.2.nakshatram": devoteeData.spouse[0].nakshatram ?? "",
          }
        : {}),
    });
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "persons",
  });

  const getNakshatra = async () => {
    const response = await getApi(apiEndPoints.nakshatram);
    if (response && response.status === 200) {
      setNakshatra(response?.data?.data);
    }
  };
  useEffect(() => {
    getNakshatra();
  }, []);

  useEffect(() => {
    nakshatra?.length > 0 && setValue("nakshatram", payload.nakshatram_id);
  }, [nakshatra]);

  const onSubmit = async (data) => {
    const result = filterById(data.pooja_id);
    const booking_config = result[0].booking_config;
    if (booking_config === "Online booking not available call temple to book") {
      const response = await postApi(apiEndPoints.getBookingConfigByTitle, {
        title: booking_config,
      });
      toast.error(response.data.data.data.message);
      return;
    }
    data.title = `${serviceData?.title} (Express Booking) `;
    data.type = 4;
    data.quantity = quantity;
    data.selectedDate = new Date(watch("starting_date"));
    data.amount = serviceData?.amount;
    const devoteeDetails = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: devoteeData?.email || "",
      country: devoteeData?.country || "",
      state: devoteeData?.state || "",
      conatct_number: devoteeData?.conatct_number || "",
      zipCode: devoteeData?.zipcode || "",
      city: devoteeData?.city,
      address: devoteeData?.address,
      persons: data?.persons?.map((item) => item),
    };
    if (!isDevoteeLoggedIn) {
      localStorage.setItem("devoteeData", JSON.stringify(devoteeDetails));
    } else {
      localStorage.setItem("devoteeData", JSON.stringify(devoteeData));
    }

    const modifiedData = { ...data };
    delete modifiedData.first_name;
    delete modifiedData.last_name;
    delete modifiedData.persons;

    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === data.pooja_id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        quantity: quantity || 1,
        selectedDate: data.starting_date,
      };
      dispatch(updateCart(updatedItem));
      navigate("/checkout");
    } else {
      const newItem = {
        ...modifiedData,
        quantity: quantity || 1,
        selectedDate: data.starting_date,
      };
      dispatch(updateCart(newItem));
      navigate("/checkout");
    }
  };

  const handleClickAddRow = () => {
    append(defaultValues);
    setVisibleRows(visibleRows + 1);
  };

  useEffect(() => {
    const startingDate = watch("starting_date");

    let newEvents = [];

    if (serviceData?.category_slug === "daily") {
      let startDate = new Date(startingDate);

      for (let i = 0; i < quantity; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + i);

        newEvents.push({
          start: currentDate,
          end: currentDate,
          title: serviceData?.title,
        });
      }
    } else if (serviceData?.category_slug === "weekly" && quantity > 0) {
      const endDate = new Date(startingDate);
      endDate.setDate(endDate.getDate() + (quantity - 1) * 7);

      newEvents = [
        {
          start: new Date(startingDate),
          end: endDate,
          title: serviceData?.title,
        },
      ];
    } else if (serviceData?.category_slug === "monthly" && quantity > 0) {
      let startDate = new Date(startingDate);

      for (let i = 0; i < quantity; i++) {
        const currentMonth = new Date(startDate);
        currentMonth.setMonth(currentMonth.getMonth() + i);

        newEvents.push({
          start: currentMonth,
          end: currentMonth,
          title: serviceData?.title,
        });
      }
    }
  }, [quantity, watch("starting_date"), serviceData?.category_slug]);

  // functions for services

  const dailyServicePayload = {
    slug: "daily",
  };
  const weeklyServicePayload = {
    slug: "weekly",
  };
  const monthlyServicePayload = {
    slug: "monthly",
  };

  const getMonthlyServicesData = async () => {
    try {
      const response = await postApi(
        apiEndPoints.weeklyServices,
        monthlyServicePayload
      );
      setMonthlyServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getWeeklyServicesData = async () => {
    try {
      const response = await postApi(
        apiEndPoints.weeklyServices,
        weeklyServicePayload
      );
      setWeeklyServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getReligiousServices = async () => {
    const response = await postApi(
      apiEndPoints.religiousServices,
      dailyServicePayload
    );

    if (response && response.status === 200) {
      setDailyServiceData(response?.data?.data);
    }
  };

  useEffect(() => {
    getMonthlyServicesData();
    getWeeklyServicesData();
    getReligiousServices();
  }, []);

  // function for getting selected service details
  const getServiceData = async () => {
    try {
      const response = await getApi(
        `${apiEndPoints.specificService}/${watch("pooja_id")}`
      );
      setServiceData(response?.data?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    watch("pooja_id") && getServiceData();
  }, [watch("pooja_id")]);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Express Booking</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Services</Link>
          </li>
          <li className="breadcrumb-item active">Express Booking</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-1">
            <span className="text-secundary">Express Booking</span>
          </h1>
        </div>
        <div className="row">
          <div className="row">
            {/* <p>
              Attention Devotees : Please fill all your family details before
              selecting Archana and Date
            </p> */}
            {/* /---------------------------------------------------- */}
            <div className="container">
              <div className="row">
                <>
                  <div className="container">
                    <Box
                      sx={{
                        "& .MuiTextField-root": {
                          minWidth: "100% !important",
                        },
                        "& .dropdown-margin-top": {
                          marginTop: "15px !important",
                        },
                        "& .accordion-background": {
                          boxShadow: "none !important",
                        },
                        "& .accordion-background::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {/* first person  */}
                        <div className="row">
                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register(`first_name`)}
                              margin="normal"
                              fullWidth
                              label="First Name 1*"
                              error={errors?.first_name?.message}
                              helperText={errors?.first_name?.message}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register(`last_name`)}
                              margin="normal"
                              fullWidth
                              label="Last Name*"
                              error={errors?.last_name?.message}
                              helperText={errors?.last_name?.message}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register("persons.0.gotram")}
                              margin="normal"
                              fullWidth
                              label="Gotram"
                              error={errors?.gotram?.message}
                              helperText={errors?.gotram?.message}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <Controller
                              control={control}
                              name="persons.0.nakshatram"
                              render={({ field, fieldState }) => {
                                return (
                                  <FormControl
                                    key={field.value}
                                    fullWidth
                                    error={fieldState?.error?.message}
                                    className="dropdown-margin-top"
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Nakshatram
                                    </InputLabel>
                                    <Select
                                      {...field}
                                      labelId="demo-simple-select-label"
                                      label="Nakshatram"
                                      defaultValue={field.value}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    >
                                      {nakshatra?.map((item) => (
                                        <MenuItem
                                          key={item._id}
                                          value={item._id}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* second person  */}
                        <div className="row">
                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register(`persons.1.first_name`)}
                              margin="normal"
                              fullWidth
                              label="First Name 2*"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register(`persons.1.last_name`)}
                              margin="normal"
                              fullWidth
                              label="Last Name *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register("persons.1.gotram")}
                              margin="normal"
                              fullWidth
                              label="Gotram"
                              error={errors?.gotram?.message}
                              helperText={errors?.gotram?.message}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <Controller
                              control={control}
                              name="persons.1.nakshatram"
                              render={({ field, fieldState }) => {
                                return (
                                  <FormControl
                                    fullWidth
                                    className="dropdown-margin-top"
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Nakshatram
                                    </InputLabel>
                                    <Select
                                      {...field}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Nakshatram"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={field.value || ""}
                                    >
                                      {nakshatra?.map((item) => (
                                        <MenuItem
                                          key={item._id}
                                          value={item._id}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* third person  */}
                        <div className="row">
                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register(`persons.2.first_name`)}
                              margin="normal"
                              fullWidth
                              label="First Name 3*"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register(`persons.2.last_name`)}
                              margin="normal"
                              fullWidth
                              label="Last Name *"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div className="col-lg-3 col-md-3">
                            <TextField
                              {...register("persons.2.gotram")}
                              margin="normal"
                              fullWidth
                              label="Gotram"
                              error={errors?.gotram?.message}
                              helperText={errors?.gotram?.message}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>

                          <div className="col-lg-3 col-md-3">
                            <Controller
                              control={control}
                              name="persons.2.nakshatram"
                              render={({ field, fieldState }) => (
                                <FormControl
                                  fullWidth
                                  className="dropdown-margin-top"
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Nakshatram
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Nakshatram"
                                    {...field} // spread the field props into Select
                                    value={field.value || ""} // set the current value (handle cases where value might be undefined)
                                  >
                                    {nakshatra?.map((item) => (
                                      <MenuItem key={item._id} value={item._id}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </div>
                        </div>
                        {/* for more persons  */}
                        <AccordionDetails>
                          {fields.slice(3).map((item, index) => (
                            <div key={item.id} className="row">
                              <ConsentDialog
                                index={index}
                                removeChild={handleChildrenRemove}
                              />
                              <div className="col-lg-3 col-md-3">
                                <TextField
                                  {...register(`persons[${index}].first_name`)}
                                  margin="normal"
                                  fullWidth
                                  label={`First Name ${index + 4}*`}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>

                              <div className="col-lg-3 col-md-3">
                                <TextField
                                  {...register(`persons[${index}].last_name`)}
                                  margin="normal"
                                  fullWidth
                                  label="Last Name *"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <TextField
                                  {...register("gotram")}
                                  margin="normal"
                                  fullWidth
                                  label="Gotram"
                                  error={errors?.gotram?.message}
                                  helperText={errors?.gotram?.message}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>

                              <div className="col-lg-3 col-md-3">
                                <FormControl
                                  fullWidth
                                  className="dropdown-margin-top"
                                >
                                  <InputLabel id="demo-simple-select-label">
                                    Nakshatram
                                  </InputLabel>
                                  <Select
                                    {...register(
                                      `persons[${index}].nakshatram`
                                    )}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Nakshatram"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  >
                                    {nakshatra?.map((item) => (
                                      <MenuItem key={item._id} value={item._id}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          ))}
                          <Box display="flex" justifyContent="end">
                            <IconButton
                              type="button"
                              color="success"
                              onClick={handleClickAddRow}
                            >
                              <AddCircle />
                            </IconButton>
                          </Box>
                        </AccordionDetails>
                        <div className="container">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <span>Name of the Puja : </span>
                            </div>

                            <div className="col-md-4">
                              <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel htmlFor="grouped-native-select">
                                  Select Service
                                </InputLabel>
                                <Select
                                  native
                                  defaultValue=""
                                  id="grouped-native-select"
                                  label="Select Service"
                                  {...register(`pooja_id`)}
                                  error={errors?.service?.message}
                                >
                                  <option aria-label="None" value="" />
                                  <optgroup label="Select Daily Puja">
                                    {dailyServiceData &&
                                      dailyServiceData.map((item, index) => (
                                        <option value={item._id}>
                                          {item.title}
                                        </option>
                                      ))}
                                  </optgroup>
                                  <optgroup label="Select Weekly Puja">
                                    {weeklyServiceData &&
                                      weeklyServiceData.map((item, index) => (
                                        <option value={item._id}>
                                          {item.title}
                                        </option>
                                      ))}
                                  </optgroup>
                                  <optgroup label="Select Monthly Puja">
                                    {monthlyServiceData &&
                                      monthlyServiceData.map((item, index) => (
                                        <option value={item._id}>
                                          {item.title}
                                        </option>
                                      ))}
                                  </optgroup>
                                </Select>
                                {errors?.pooja_id && (
                                  <FormHelperText error={true}>
                                    {errors.pooja_id.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="container">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">Quantity :</div>
                            <div className="col-md-4">
                              <IconButton
                                color="error"
                                aria-label="decrease quantity"
                                onClick={decrementQuantity}
                              >
                                <RemoveCircle />
                              </IconButton>
                              {quantity}
                              <IconButton
                                color="success"
                                aria-label="increase quantity"
                                onClick={incrementQuantity}
                              >
                                <AddCircle />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        {/* starting date  */}
                        <div className="container">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <div>Puja Date : </div>
                            </div>
                            <div className="col-md-3">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                  <Controller
                                    rules={{
                                      validate: async (value) =>
                                        await schema.isValid({
                                          starting_date: value,
                                        }),
                                    }}
                                    control={control}
                                    name={`starting_date`}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <DatePicker
                                          {...field}
                                          label="Select a date"
                                          shouldDisableDate={
                                            serviceData &&
                                            serviceData?.category_slug ===
                                              "weekly"
                                              ? (date) => {
                                                  const currentDate =
                                                    new Date();
                                                  if (date < currentDate) {
                                                    return true;
                                                  }
                                                  return (
                                                    new Date(date).getDay() !==
                                                    Number(serviceData?.days)
                                                  );
                                                }
                                              : serviceData?.category_slug ===
                                                "monthly"
                                              ? (date) => {
                                                  const currentDate =
                                                    new Date();
                                                  if (date < currentDate) {
                                                    return true;
                                                  }

                                                  const year = new Date(
                                                    date
                                                  ).getFullYear();
                                                  const month = new Date(
                                                    date
                                                  ).getMonth();

                                                  const firstDayOfMonth =
                                                    new Date(year, month, 1);

                                                  const firstDayOfWeek =
                                                    firstDayOfMonth.getDay();

                                                  const offset =
                                                    (serviceData?.days -
                                                      firstDayOfWeek +
                                                      7) %
                                                    7;

                                                  const firstSelectableDate =
                                                    new Date(
                                                      year,
                                                      month,
                                                      1 +
                                                        offset +
                                                        (Number(
                                                          serviceData?.positions
                                                        ) -
                                                          1) *
                                                          7
                                                    );

                                                  return (
                                                    new Date(date).getDate() !==
                                                      firstSelectableDate.getDate() ||
                                                    new Date(
                                                      date
                                                    ).getMonth() !==
                                                      firstSelectableDate.getMonth() ||
                                                    new Date(
                                                      date
                                                    ).getFullYear() !==
                                                      firstSelectableDate.getFullYear()
                                                  );
                                                }
                                              : serviceData?.category_slug ===
                                                "daily"
                                              ? (date) => {
                                                  const currentDate =
                                                    new Date();
                                                  currentDate.setHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  );
                                                  return date < currentDate;
                                                }
                                              : undefined
                                          }
                                        />
                                        {fieldState.error && (
                                          <FormHelperText error>
                                            {fieldState.error.message}
                                          </FormHelperText>
                                        )}
                                      </>
                                    )}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                            <div className="d-flex gap-5 mt-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* price */}
                        <div className="container mt-2">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <span>Price :</span>
                            </div>

                            <div className="col-md-3">
                              $ {serviceData?.amount * quantity || 0}
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="warning text-white btn-slider slider-btn col-lg-3 col-md-6 mt-3 py-3"
                          type="submit"
                        >
                          Proceed to Pay
                        </Button>
                      </form>
                    </Box>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpressBookingServices;
