import { useEffect, useState } from "react";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

export const useServicesOutsideTempleFunctions = () => {
  const cartArr = useSelector((state) => state.cartArr);
  const [serviceData, setServiceData] = useState();
  const [selectedDate, setSelectedDate] = useState({});
  const dispatch = useDispatch();
  const { control } = useForm({});

  const getWeeklyServicesData = async () => {
    try {
      const response = await getApi(apiEndPoints.outsideTempleServices);
      setServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderPuja = async (item) => {
    if (
      item.booking_config === "Online booking not available call temple to book"
    ) {
      const response = await postApi(apiEndPoints.getBookingConfigByTitle, {
        title: item.booking_config,
      });
      toast.error(response.data.data.data.message);
      return;
    }
    if (!selectedDate[item._id]) {
      toast.error("Please select a date.");
      return;
    }

    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        type_id: 2,
        page: "outsidetemple",
        quantity: 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(updatedItem));
    } else {
      const newItem = {
        ...item,
        type_id: 2,
        page: "outsidetemple",
        quantity: 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(newItem));
    }
  };

  useEffect(() => {
    getWeeklyServicesData();
  }, []);

  return {
    serviceData,
    selectedDate,
    setSelectedDate,
    control,
    handleOrderPuja,
  };
};
