import * as React from "react";
import "./tvgallery.css";
import Carousel from "react-bootstrap/Carousel";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function Tvgallery() {
  const [tvGalleryItems, setTvGalleryItems] = React.useState([]);

  const getCarouselData = async () => {
    const res = await getApi(apiEndPoints?.get_tvgallery_events);

    const res1 = await getApi(apiEndPoints?.getAllNewsForTvGallery);

    // Concatenate the data from both responses
    const combinedData = [
      ...(res?.data?.data || []),
      ...(res1?.data?.data || []),
    ];

    // Set the combined data to state
    setTvGalleryItems(combinedData);
  };

  React.useEffect(() => {
    getCarouselData();
  }, []);
  return (
    <>
      <Carousel data-bs-theme="dark">
        {tvGalleryItems?.map((item) => (
          <Carousel.Item className="tvgallery-bg1">
            <div className="row">
              <div className="col-md-6">
                <div className="tvg-left d-flex justify-content-center align-items-center">
                  <img
                    className="d-block slider-images"
                    src={item?.image}
                    alt="First slide"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="tvg-right d-flex justify-content-center align-items-center">
                  <Carousel.Caption className="slider-text">
                    <h5
                      className="h5_gallery"
                      style={{ wordBreak: "break-word" }}
                    >
                      {item?.title}
                    </h5>
                    <ul className="tvgallery-list">
                      <li>
                        <span>Date : </span>Thursday, 9th May 2024
                      </li>
                      <li>
                        <span>Time : </span>5:00 PM
                      </li>
                      <li>
                        <span>Venue : </span>Prayer Hall(Upstairs)
                      </li>
                    </ul>
                    <div
                      className="news-desc"
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                    />
                  </Carousel.Caption>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default Tvgallery;
