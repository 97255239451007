export const apiEndPoints = {
  todaySchedule: "get_today_puja_schedule",
  panchang: "get_panchang",
  news: "get_latest_news",
  liveStreamingUrl: "get_live_streaming",
  getIndividualNews: "get_news_detail",
  allNews: "get_all_news",
  allEvents: "get_all_events",
  getSpecificEvent: "get_specific_event",
  viewAllHomePageEvents: "view_all_events",
  spotlight: "get_spotlight",
  slider: "get_slider",
  allServices: "get_service_categories",
  specificService: "get_specific_service",
  monthly_newsletter: "get_monthly_newsletter",
  annualCalendar: "get_annual_calendar",
  outsideServices: "get_outside",
  religiousServices: "service/temple",
  outsideTempleServices: "service/get_outside",
  serviceDetails: "get_service_detail",
  nakshatram: "get_nakshatram",
  nakshatrambyrashi: "get_nakshatram_rashi",
  rashi: "get_rashi",
  getBalGokul: "baalgokul/list",
  rashibynakshatramid: "get_rashi_nakshatram",
  languages: "get_language",
  countries: "get_country",
  getProfile: "get_profile",
  state: "get_state",
  devoteeRegister: "register",
  devoteeUpdate: "update_profile",
  devoteeLogin: "login",
  allHomePageEvents: "get_homepage_events",
  forgotPassword: "forgot_password",
  weeklyServices: "service/temple",
  isValidResetToken: "is_valid_reset_token",
  resetPassword: "reset_password",
  changePassword: "change_password",
  scroller: "get_scroller",
  galleryCategories: "get_gallery_categories",
  galleryFestivals: "get_gallery_festivals",
  galleryTags: "get_all_gallery_tags",
  allGallery: "get_gallery",
  galleryPhotos: "get_gallery_photos",
  getRental: "service/get_rental",
  getRentalPurpose: "service/rentals/purposes",
  checkAvailability: "check_availability",
  galleryVideos: "get_gallery_videos",
  stripePayment: "make_stripe_payment",
  stripePaymentForPrasadamServices: "make_stripe_payment_for_prasadam",
  getAllDonations: "get_donation",
  getDonationByCategory: "get_donation_detail",
  sendRejectionMail: "send_rejection",
  getBookingData: "get_booking",
  getOrderDetails: "get_order_detail",
  contactUs: "contact-us/store",
  subscribe: "subscribe",
  verifyNewsLetter: "verify_news_letter_otp",
  volunteer: "volunteer/Add",
  registerOrderDetails: "registerOrderDetails",
  addSankalp: "add_sankalp",
  getTempleConfigByCode: "getTempleConfigByCode",
  getPriests: "priest/getAll",
  getAllCateringItems: "getAll_cateringItems",
  getAllCafeteriaItems: "get_cafeteriaItems",
  getSearchData: "searchbar/getall",
  get_tvgallery_events: "get_tvgallery_events",
  getAllNewsForTvGallery: "getAllNewsForTvGallery",
  createReoccurigDonationSubscription: "reoccuring_donation",
  getAllCateringItems: "getAll_cateringItems",
  getAllCafeteriaItems: "get_cafeteriaItems",
  recurringDonationPayment: "reoccuring_donation_v1",
  createReoccurigDonationSubscription: "reoccuring_donation",
  balGokulPayment: "make_stripe_payment_For_BalGokul",
  balGokulBooking: "baalgokulorder/submit",
  getUpcomingEvents: "get_upcoming_events",
  allMeraApne: "mereapne/getAll",
  allPbj: "pbj/getAll",
  allYouthTruth: "youthtruth/getAll",
  allPujaSamagri: "pujasamagri/getAll",
  allReligiousBooks: "religiousbooks/getBooks",
  getAllbooksLanguages: "booksLanguages/getAll",
  getAllbooksCategory: "booksCategory/getAll",
  getUpcomingEvents: "get_upcoming_events",
  getBookingConfigByTitle: "getBookingConfigByTitle",
};

export const galleryType = {
  photo: "photo",
  video: "video",
};
