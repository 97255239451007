import * as React from "react";
import "./CalendarView.css";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { apiEndPoints } from "../../../../ApiServices/config";
import { postApi } from "../../../../ApiServices/api";

const CustomEvent = ({ event, currentView }) => {
  const startTime = moment(event.start).format("hh:mm A");
  const endTime = moment(event.end).format("hh:mm A");
  if (currentView === "month") {
    return (
      <div className="custom-event">
        <div className="event-time">
          {startTime} - {endTime}
        </div>
        <strong>
          {" "}
          <Link to={`/knowmoreevents/${event.slug}`} style={{ color: "#fff" }}>
            {event?.title}
          </Link>
        </strong>
      </div>
    );
  }
  // Default rendering for other views (week, day)
  return (
    <span>
      <strong>{event.title}</strong>
    </span>
  );
};

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.date.setDate(toolbar.date.getDate() - 7);
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    toolbar.date.setDate(toolbar.date.getDate() + 7);
    toolbar.onNavigate("next");
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span>
        <b>{date.format("MMMM")}</b>
        <span> {date.format("YYYY")}</span>
      </span>
    );
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>
          Back
        </button>
        <button type="button" onClick={goToCurrent}>
          Today
        </button>
        <button type="button" onClick={goToNext}>
          Next
        </button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => toolbar.onView("month")}>
          Month
        </button>
        <button type="button" onClick={() => toolbar.onView("week")}>
          Week
        </button>
        <button type="button" onClick={() => toolbar.onView("day")}>
          Day
        </button>
        <button type="button" onClick={() => toolbar.onView("agenda")}>
          List
        </button>
      </span>
    </div>
  );
};

function CalenderView() {
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("month");
  const localizer = momentLocalizer(moment);

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const viewParam = queryParams.get("view");
    if (viewParam) {
      setCurrentView(viewParam);
    }
  }, [location]);

  const viewShedule = async () => {
    const response = await postApi(apiEndPoints.getUpcomingEvents);

    const mergedArray = [
      ...(response.data?.today || []).map((event) => ({
        ...event,
        allDay: false,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
      ...(response.data?.weekly || []).map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
      ...(response.data?.monthly || []).map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      })),
    ];

    const uniqueEvents = removeDuplicates(mergedArray);
    setEvents(uniqueEvents);
  };
  const removeDuplicates = (events) => {
    const uniqueEvents = {};
    events.forEach((event) => {
      const key = `${event.start}-${event.end}-${event.title}`;
      if (!uniqueEvents[key]) {
        uniqueEvents[key] = event;
      }
    });
    return Object.values(uniqueEvents);
  };

  useEffect(() => {
    viewShedule();
  }, []);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">CalendarView</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">Calendar View</li>
        </ol>
      </div>
      <div className="container py-5">
        <div style={{ height: 500, width: "100%" }} className="rbc-calendar">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={new Date()}
            view={currentView}
            onView={(view) => setCurrentView(view)}
            ampm={true}
            components={{
              event: (props) => (
                <CustomEvent {...props} currentView={currentView} />
              ),
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CalenderView;
