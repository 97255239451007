import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePrasadamCart } from "../../actions";
import toast from "react-hot-toast";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { updateCart } from "../../actions";
// import { toast } from "react-hot-toast";

export const useServicesCommonFunctions = () => {
  const [quantityCounts, setQuantityCounts] = useState({});
  const cartArr = useSelector((state) => state.cartArr);
  const dispatch = useDispatch();

  const increaseQuantity = (itemId) => {
    setQuantityCounts((prevCounts) => ({
      ...prevCounts,
      [itemId]: (prevCounts[itemId] || 0) + 1,
    }));
  };

  const decreaseQuantity = (itemId) => {
    if (quantityCounts[itemId] > 1) {
      setQuantityCounts((prevCounts) => ({
        ...prevCounts,
        [itemId]: prevCounts[itemId] - 1,
      }));
    }
  };

  const handleOrderPuja = (item, bookingType, page) => {
    if (item.amount !== 0) {
      const existingCartItem = cartArr.find(
        (cartItem) => cartItem._id === item._id
      );
      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          type_id: 1,
          page: page,
          quantity: quantityCounts[item._id] || 1,
        };
        dispatch(updateCart(updatedItem));
        toast.success("Your Food Item has been successfully added.");
      } else {
        const newItem = {
          ...item,
          type_id: 1,
          page: page,
          quantity: quantityCounts[item._id] || 1,
        };
        dispatch(updateCart(newItem));
        toast.success("Your Food Item has been successfully added.");
      }
    } else {
      toast.error(
        "You can't book this food item. For more information, please contact the temple."
      );
    }
  };

  return {
    quantityCounts,
    handleOrderPuja,
    increaseQuantity,
    decreaseQuantity,
  };
};

export const useCateringFunctions = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const prasadamArr = useSelector((state) => state.prasadamArr);
  const dispatch = useDispatch();

  const {
    watch,
    control,
    formState: { errors },
  } = useForm({});

  const getCateringItems = async () => {
    const res = await getApi(apiEndPoints?.getAllCateringItems);
    setData(res?.data?.data);
  };

  React.useEffect(() => {
    getCateringItems();
  }, []);
  const handleAddItem = (item) => {
    const existingCartItem = prasadamArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        itemQuantitiy: item?.itemQuantitiy,
      };
      dispatch(updatePrasadamCart(updatedItem));
      toast.success("Your puja/donation is updated");
    } else {
      const newItem = {
        ...item,
        itemName: item?.itemName,
        _id: item?._id,
        amount: item?.amount,
      };
      dispatch(updatePrasadamCart(newItem));
      toast.success("Your puja/donation added successfully on puja basket");
    }
  };

  return { data, watch, control, handleAddItem };
};

export const useCafeteriaFunctions = () => {
  const [data, setData] = React.useState([]);
  const prasadamArr = useSelector((state) => state.prasadamArr);
  const dispatch = useDispatch();

  const { watch } = useForm({});

  const getCafeteriaItems = async () => {
    const res = await postApi(apiEndPoints?.getAllCafeteriaItems);
    setData(res?.data);
  };

  React.useEffect(() => {
    getCafeteriaItems();
  }, []);

  const handleAddItem = (item) => {
    const existingCartItem = prasadamArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        itemQuantitiy: item?.itemQuantitiy,
      };
      dispatch(updatePrasadamCart(updatedItem));
      toast.success("Your puja/donation is updated");
    } else {
      const newItem = {
        ...item,
        itemName: item?.itemName,
        itemQuantitiy: item?.itemQuantitiy,
        _id: item?._id,
        amount: item?.amount,
      };
      dispatch(updatePrasadamCart(newItem));
      toast.success("Your puja/donation added successfully on puja basket");
    }
  };

  return { data, handleAddItem, watch };
};
