import React, { useEffect, useState } from "react";
import "../panchangam/panchangam.css";
import "./newsevents.css";

import moment from "moment-timezone";
import { Box, Tab, Typography } from "@mui/material";
import { Link } from "react-router-dom";
function NewsEventsSection(props) {
  const { dailyNews, allEvents } = props;

  return (
    <>
      <div className="container-fluid panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-5">
            <div className="col-lg-6 col-md-6">
              <div className="text-center h-100 pt-0 panchang">
                <React.Fragment>
                  <div className="text-center h-100 pt-0 panchang">
                    <div className="title-bg">
                      <h2>News</h2>
                    </div>

                    <div className="panchang-content2">
                      <Box
                        sx={{
                          p: 3,
                          maxHeight: "400px",
                          overflowY: "auto",
                          width: "100%",
                        }}
                      >
                        {dailyNews &&
                          dailyNews.map((item, i) => (
                            <Box sx={{ p: 3 }} key={i}>
                              <div className="content-algn">
                                <Typography variant="p" className="news-title1">
                                  {item?.date}
                                </Typography>
                                <Typography variant="p">
                                  <div className="mb-3">
                                    <div>
                                      <Typography
                                        variant="p"
                                        className="news-title"
                                      >
                                        {item?.title}
                                      </Typography>
                                      <div>
                                        <img
                                          src={item?.image}
                                          alt={item?.title}
                                          className="news-title-img"
                                        />
                                      </div>
                                      <div
                                        className="news-desc"
                                        dangerouslySetInnerHTML={{
                                          __html: item?.description,
                                        }}
                                      />

                                      <Link
                                        className="btn text-white py-2 px-4"
                                        to={`/knowmorenews/${item.slug}`}
                                      >
                                        Know more
                                      </Link>
                                    </div>
                                  </div>
                                </Typography>
                              </div>
                            </Box>
                          ))}
                      </Box>
                    </div>
                    <div className="bottom-more">
                      <Link className="btn text-white py-2 px-4" to="/news">
                        View all
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>

            <div className={"col-lg-6 col-md-6"}>
              <div className="text-center h-100 pt-0 panchang">
                <React.Fragment>
                  <div className="text-center h-100 pt-0 panchang">
                    <div className="title-bg">
                      <h2>Events</h2>
                    </div>

                    <div className="panchang-content2">
                      <Box sx={{ p: 3, maxHeight: "400px", overflowY: "auto" }}>
                        {allEvents &&
                          allEvents.map((event, i) => (
                            <div key={i} className="content-algn">
                              <Typography variant="p" className="news-title1">
                                {moment
                                  .utc(event?.event_start)
                                  .format("dddd, MMMM DD, YYYY")}
                              </Typography>
                              <Typography variant="p">
                                <div className="mb-4">
                                  <div>
                                    <Typography
                                      variant="p"
                                      className="news-title"
                                    >
                                      {event?.title}
                                    </Typography>
                                    <div>
                                      <img
                                        src={event?.image}
                                        alt={event?.title}
                                        className="news-title-img"
                                      />
                                    </div>
                                    <div
                                      className="news-desc"
                                      dangerouslySetInnerHTML={{
                                        __html: event?.description,
                                      }}
                                    />

                                    <Link
                                      className="btn text-white py-2 px-4"
                                      to={`/knowmoreevents/${event.slug}`}
                                    >
                                      Know more
                                    </Link>
                                  </div>
                                </div>
                              </Typography>
                            </div>
                          ))}
                      </Box>
                    </div>
                    <div className="bottom-more">
                      <Typography variant="p">
                        <Link className="btn text-white py-2 px-4" to="/events">
                          View all
                        </Link>
                      </Typography>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewsEventsSection;
