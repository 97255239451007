import React, { useEffect, useState } from "react";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
function useLoadMasterDataAllDonation() {
  const [masterData, setMasterData] = useState();
  const cartArr = useSelector((state) => state.cartArr);
  const dispatch = useDispatch();
  const [donationAmount, setDonationAmount] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  useEffect(() => {
    getMasterData();
  }, []);

  const handleDonationAmount = (item, e) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
      setErrorMessage((prev) => ({
        ...prev,
        [item._id]: "Please enter only digits",
      }));
      return;
    }
    
    if (value === "" || value < item.amount) {
      setErrorMessage((prev) => ({
        ...prev,
        [item._id]:
          value === ""
            ? "Please enter a donation amount"
            : `Minimum donation amount is $${item.amount}`,
      }));
    } else {
      setErrorMessage((prev) => ({ ...prev, [item._id]: "" }));
    }

    setDonationAmount((prev) => ({
      ...prev,
      [item._id]: value,
    }));
  };

  const handleOrderPuja = (item) => {
    const amount = donationAmount[item._id];

    if (!amount || amount < item.amount) {
      setErrorMessage((prev) => ({
        ...prev,
        [item._id]: !amount
          ? "Please enter a donation amount"
          : `Minimum donation amount is $${item.amount}`,
      }));
      return; // Prevent further execution if validation fails
    }

    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        amount: donationAmount[item._id],
        type: parseInt(item.type),
        type_id: 5,
        location: "",
        page: "services/donation",
      };
      dispatch(updateCart(updatedItem));
    } else {
      const newItem = {
        ...item,
        amount: donationAmount[item._id],
        type: parseInt(item.type),
        type_id: 5,
        location: "",
        page: "services/donation",
      };
      dispatch(updateCart(newItem));
    }
  };

  const getMasterData = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setMasterData(response?.data?.data);
    }
  };

  return {
    masterData,
    donationAmount,
    handleDonationAmount,
    errorMessage,
    handleOrderPuja,
  };
}

export default useLoadMasterDataAllDonation;
