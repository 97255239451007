import { CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import "./gallery.css";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";

export default function Videos() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [galleryVideos, setGalleryVideos] = React.useState([]);
  const { slug } = useParams();

  const getPhotos = async () => {
    setIsLoading(true);
    const payload = {
      slug: slug,
    };
    const getVideos = await postApi(apiEndPoints.galleryVideos, payload);

    getVideos?.data?.status == 1
      ? setGalleryVideos(getVideos?.data?.data?.videos)
      : setGalleryVideos([]);

    setIsLoading(false);
  };

  useEffect(() => {
    getPhotos();
  }, []);

  return (
    <>
      <div className="container-fluid gallery-bg py-5">
        <div className="container pt-4">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple Videos
            </h6>
            <h1 className="mb-5">
              <span className="text-secundary">Gallery</span>
            </h1>
          </div>
          <IconButton onClick={() => navigate(-1)} className="mb-5">
            <ArrowBack />
          </IconButton>

          <Grid container spacing={2} justifyContent="center">
            {isLoading ? (
              <CircularProgress />
            ) : galleryVideos?.length > 0 ? (
              galleryVideos.map((video) => {
                let embedUrl = "";
                try {
                  const url = new URL(video.url);

                  if (url.hostname.includes("youtube.com")) {
                    const videoId = url.searchParams.get("v"); // Extract YouTube video ID
                    if (videoId) {
                      embedUrl = `https://www.youtube.com/embed/${videoId}`;
                    } else {
                      console.warn("Missing YouTube video ID:", video.url);
                      return null;
                    }
                  } else if (
                    url.hostname.includes("facebook.com") &&
                    url.pathname.includes("/videos/")
                  ) {
                    const videoId = url.pathname
                      .split("/videos/")[1]
                      ?.split("/")[0];
                    if (videoId) {
                      embedUrl = `https://www.facebook.com/plugins/video.php?href=${video.url}&show_text=false&width=560&height=314`;
                    } else {
                      console.warn("Missing Facebook video ID:", video.url);
                      return null;
                    }
                  } else {
                    console.warn("Unsupported video URL:", video.url);
                    return null; // Skip unsupported URLs
                  }
                } catch (error) {
                  console.error("Invalid URL format:", video.url);
                  return null; // Skip invalid URLs
                }

                return (
                  <Grid item key={video.url} xs={12} sm={6} md={4}>
                    <iframe
                      src={embedUrl}
                      width="560"
                      height="314"
                      scrolling="no"
                      frameBorder="0"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen={true}
                      style={{ width: "100%", height: "315px" }}
                    ></iframe>
                  </Grid>
                );
              })
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: "#555",
                  marginTop: "1rem",
                }}
              >
                Currently, there are no event videos. Please check in the future
                for all updated event videos.
              </p>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
